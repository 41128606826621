<!-- @mouseover="mouseoverFunc"  -->
<template>
  
  <div :class="cardType" ref="cardMianRef" @mouseenter="eidtBtnDisplay" @mouseleave="handleMouseLeave">
   
    <div @click="showFunc" v-if="childRen"  class="firstChild">
    <div style="color: #FFF221;font-size: 22px;text-shadow: 2px 2px 2px #000000;">· {{ name }} · </div>
    <!-- <div class="close">点击关闭</div> -->
  </div>

    <div class="showHidden"  v-if="!childRen" @click="childRen = true">
        <div >{{ name }}</div>
        <!-- <div> -->
          <!-- <div class="openallStyle" >展开全部</div> -->
          <!-- <div class="line"></div> -->
        <!-- </div> -->
       

    </div>
    <transition name="slide">
    <slot @mouseenter="handleMouseLeave"  v-if="childRen"></slot>
  </transition>
    <div class="topMenu" v-show="editType" v-if="bigPictureEdit">
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          <el-button>编辑</el-button>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="editNameFunc">名称</el-dropdown-item>
          <!-- <el-dropdown-item  @click.native="editDataSource" v-if="this.showEditMap" >编辑</el-dropdown-item> -->
          <el-dropdown-item @click.native="delAll">删除</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>

</template>




<script>
import { render } from 'nprogress'
import CollapseTransition from 'vue-collapse-transition'
export default {
  props: {
    name: {
      type: String,
      default: '新闻板块',
    },
    cardType: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
  },
  components: {
    CollapseTransition
  },
  data() {
    return {
      editType: false,
      inputValue: '',
      showEditMap: true,
      childRen: false,
    }
  },
  methods: {
    selectTab(index) {},
    eidtBtnDisplay(e) {
      // const index = e.target.getAttribute('data-index');

      this.editType = true
    },
    handleMouseLeave() {
      this.editType = false
    },

    // /**
    //  * @Author: YangXin
    //  * @Description: 判断边框样式添加
    //  * @Date: 2024-08-03 14:26:28
    //  * @return {*}
    //  */
    // cardTypefunc() {
    //   switch (this.cardType) {
    //     case "card":
    //       return "card"
    //     case "bezel_less":
    //       return "bezel_less"
    //     default:
    //       return "card"
    //   }
    // },
    handleInput(value) {
      // 更新本地数据以模拟 v-model 的双向绑定
      this.inputValue = value
    },

    showFunc() {
      this.childRen = false
      console.dir(this.$refs.cardMianRef)
      this.$refs.cardMianRef.style.minWidth = "139px"
      // if(this.$refs.cardMianRef.className == "showHidden"){
      //   this.$refs.cardMianRef.className = "card"

      // }else{
      //   this.$refs.cardMianRef.className = "showHidden"

      // }
    },
    editNameFunc() {
      this.$prompt('请输入名称', '修改', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: this.currentDepartment[this.$attrs.dataCardIndex].name,
      })
        .then(({ value }) => {
          this.currentDepartment[this.$attrs.dataCardIndex].name = value
          this.$message({
            type: 'success',
            message: '添加成功',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入',
          })
        })
    },

    editDataSource: function () {
      this.$emit('showEditMapData')
    },

    delAll: function () {
      document.body.style.overflow = ''
      this.$confirm('检测到要删除全部？', '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '删除',
        cancelButtonText: '放弃删除',
      })
        .then(() => {
          console.log(this)
          document.body.style.overflow = ''

          this.currentDepartment.splice(this.$attrs.dataCardIndex, 1)
          console.log(this.currentDepartment)
          this.$message({
            type: 'info',
            message: '删除成功',
          })
        })
        .catch(action => {
          this.$message({
            type: 'info',
            message: action === 'cancel' ? '放弃保存并离开页面' : '停留在当前页面',
          })
        })
    },
  },
  computed: {
    currentDepartment() {
      return this.$store.getters['schema/schema'].value
    },
    bigPictureEdit() {
      return this.$store.getters['bigPictureEditType']
    },
  },
}
</script>


<style scoped lang="scss">
.topMenu {
  top: 0;
  left: 20px;
  position: absolute;
  z-index: 1;
}
.showHidden {
  width: 30px;
  height: 85vh;
  min-width: 139px !important;
  //  margin-top:15vh;
  overflow: hidden;
  // display: flex;
  // align-items: center;
  // animation: widthAnimation 2s;
  background: url('~@/assets/images/data/daohang.png');
  background-size: 100% 100%;
  //  border: 1px solid #2E6099;
  margin: 20px 15px;

  & > div:first-child {
    height: 52%;
    width: 30px;
    /* 水平垂直居中 */
    margin-top:148px;
    margin-left: 22px;
    display: flex;
    // align-items: center;
    padding-left: 30px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 700;
    font-size: 2rem;
    color: #ffffff;
    line-height: 42px;
    text-align: center;
    // margin-right: 32px;
  }
  .openallStyle{
    height: 12%;
    width: 27px;
    display: flex;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    margin-left: 54px;
  } 
    .openallStyle:hover{
      color: #7de7fd;
    }
  .line{
    height: 40px;
    width: 3px;
    margin-left: 66px;
    background: #ffffff;
    font-size: 16px;
    margin-top: 10px;
  }
}

@keyframes widthAnimation {
  from {
    margin-top: 0vh;
    width: 90%; /* 起始宽度 */
    height: 95vh;
  }
  to {
    margin-top: 15vh;
    width: 200px; /* 结束宽度 */
    height: 60vh;
  }
}

.card {
  width: max-content;
  min-height: calc(8vh - 52px - 72px);
  background: rgba(11, 70, 127, 0.34);
  border-radius: 25px;
  border: 1px solid #2e6099;
  backdrop-filter: blur(4px);
  background-image: url('~@/assets/images/data/2.png'), url('~@/assets/images/data/3.png'),
    url('~@/assets/images/data/1.png'), url('~@/assets/images/data/4.png');
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-position: top left, top right, bottom left, bottom right;
  z-index: 1;
  padding: 2px 2px 3px 2px; //设置padding给div往里挤

  margin-right: 28px;
  transition: all 1s;
  &:first-child {
    margin-left: 48px;
  }

  display: flex;



  & > div:not(div:first-child) {
    //background-color: #06a37e;
    width: 100%;
    min-width: 260px;
    // margin: 0 14px 0 0;
    div{
      font-size: 20px;
    }
  }
}

  .firstChild {
    height: calc(100vh - 50px - 72px);
    width: 45px;
    background-image: url('~@/assets/images/data/window-left.png');
    background-repeat: no-repeat;
    background-position: center center;
    /* 水平垂直居中 */

    margin-left: -27px;
    display: flex;
    flex-direction: column;
   justify-content: center;
   align-items: center;
    padding-left: 30px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    line-height: 29px;
    text-align: center;
    margin-right: 23px;
    // writing-mode: vertical-rl; 
    >div{
      padding-left: 24px;
    }
  }
  .close{
    font-size: 16px;
    &:hover{
      color: #7de7fd;
    }
  }
// 添加100%框架样式
.card2 {
  width: max-content;
  min-height: calc(100vh - 52px - 72px);
  background: rgba(11, 70, 127, 0.34);
  border-radius: 42px;
  border: 1px solid #2e6099;
  backdrop-filter: blur(4px);

  background-image: url('~@/assets/images/data/zs.png'), url('~@/assets/images/data/ys.png'),
    url('~@/assets/images/data/zx.png'), url('~@/assets/images/data/yx.png');
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-position: top left, top right, bottom left, bottom right;
  z-index: 1;
  padding: 2px 2px 3px 2px; //设置padding给div往里挤

  margin-right: 58px;

  &:first-child {
    margin-left: 48px;
  }

  display: flex;

  & > div:first-child {
    height: calc(100vh - 50px - 72px);
    width: 30px;
    background-image: url('~@/assets/images/data/window-left.png');
    background-repeat: no-repeat;
    background-position: center center;
    /* 水平垂直居中 */

    margin-left: -27px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    line-height: 29px;
    text-align: center;
    margin-right: 32px;
  }

  & > div:not(div:first-child) {
    //background-color: #06a37e;
    width: 100%;
    min-width: 470px;
    margin: 24px 24px 24px 0;
    transition: all 1s;
   
  }
}

.bezel_less {
  width: max-content;
  min-height: calc(100vh - 52px - 72px);
  // background: rgba(11, 70, 127, 0.34);
  // border-radius: 42px;
  // border: 1px solid #2E6099;
  border: 2px dashed #a0e7cd;
  // backdrop-filter: blur(4px);

  padding: 2px 2px 3px 2px; //设置padding给div往里挤

  margin-right: 58px;

  &:first-child {
    margin-left: 48px;
  }

  display: flex;

  & > div:first-child {
    height: calc(100vh - 50px - 72px);
    width: 30px;
    // background-image: url("~@/assets/images/data/window-left.png");
    // background-repeat: no-repeat;
    // background-position: center center;
    /* 水平垂直居中 */

    margin-left: -27px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    line-height: 29px;
    text-align: center;
    margin-right: 32px;
  }

  & > div:not(div:first-child) {
    //background-color: #06a37e;
    width: 100%;
    min-width: 40px;
    margin: 24px 24px 24px 0;
  }
}
.slide-enter-active{
  transition: transform .5s ease-out;
  
}
.slide-enter,.slide-leave-to{
  transform: translateX(-26px);
  opacity: 0;
}
</style>
