import axios from 'axios';
axios.defaults.withCredentials = true;

// 定义请求配置
const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};

import gzap from "../mock/home/gzap.json"
import sczt from "../mock/home/sczt.json"
import yzt from "../mock/home/yzt.json"
import home from "../mock/home/home.json"


export const apiMixin = {
  data() {
    return {
      apiServer: "",
      nmdtItems:[{list:[]},{list:[]}],
      publicInfoList:[],
      gzapData:gzap,
      sczt:{
        "area": "",
        "bodyName": "",
        "business": "",
        "corporateChampion": "",
        "enterpriseType": "",
        "isLeadingEnterprise": ""
      },
      scztResultData:sczt.data,
      // yztResultDate:this.processData(yzt.data),
      deps:home,
      currentDepartment:home["home"]
    };
  },
  created() {


  },
  methods: {

    async fetchData() {
      this.gzdtData();
      this.scztData();
      try {
        const response = await axios.get(this.apiServer+'https://bus.xiamentianyun.com:9080/jzjd-admin/nync/compose?phone=15034933020');
        this.nmdtItems=response.data;

      } catch (error) {
        console.error('请求失败:', error);
      }
    },
    async gzdtData() {

      try {
        const response = await axios.get("https://bus.xiamentianyun.com:18089/mabp/pub/getPublicInfoList");
        this.publicInfoList=response.data;

      } catch (error) {
        console.error('请求失败:', error);
      }
    },

    async scztData() {
      let that = this
      try {
        const response = await axios.post(
          "http://49.232.147.8:8080/jzjd-admin/app/mainBody/allList",
          this.sczt,
          config
        );
        that.scztResultData=response.data.data;

       // EventBus.$emit('scztDataLoaded', this.scztResultData); // 发布事件
      } catch (error) {
        console.error('请求失败:', error);

      }
    },
    async qhrlData() {
      let that = this
      try {
        const response = await axios.post(
          "http://49.232.147.8:8080/jzjd-admin/objects/getCamerList",
          this.sczt,
          config
        );
        that.qhrlResultData=response.data.data;

       // EventBus.$emit('scztDataLoaded', this.scztResultData); // 发布事件
      } catch (error) {
        console.error('请求失败:', error);

      }
    },

  }
};
